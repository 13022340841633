.set-shipping-policy-modal-content {
    padding: 20px;

    .set-shipping-policy-footer {
        margin-top: 25px;  

        div {
            float: right;
            margin-bottom: 15px;
        }

        button:last-child {
            margin-left: 10px;
        }

        button {
            width: 120px;
        }
    }

    .loader-container {
        display: flex;
        justify-content: center;
    }
}