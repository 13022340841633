.feed-history {

    min-width: 300px;

    .delete-template-btn {
        margin-right: 5px;
    }
    
    .card-inner {
        margin: 1.5rem;
        
    }

    .refresh-feeds-btn {
        width: 100%;
    }
    
    .refresh-feeds-btn .btn {
        float: right;
    }

    .manage-cell {
        text-align: right;
        .ag-cell-wrapper {
            display: initial;
        }
    }

    .card-header h4 {
        width: 160px;
    }

    .manage-btns {
        padding: 3px;
    }

    .manage-header {
        height: 0;
    }
}