.field-mappings {

    height: calc(100% - 165px);

    .card-inner {
        padding: 0 1.5rem 1.5rem 1.5rem;
        height: 100%;
    }

    .nav-tabs {
        margin: 0;
    }

    .field-mappings-loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mapping-row {
        display: flex;
        margin-bottom: 5px;
    }

    .mapping-row .mapping-name {
        width: 200px;
    }

    .attribute-selection {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .basic-single {
        line-height: initial !important;
    }
}

.field-mappings-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: none;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
    z-index: -1;
}

.field-mappings-modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10;
}