.kogan-install {
    .card-inner {
        padding: 1.5rem;
    }

    .install-footer {
        float: left;
        margin-top: 1.5rem;
    }

    .install-kogan-form {
        margin-top: 20px;
    }

    .api-key-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .edit-api-key {
        font-size: 20px;
    }

    .edit-api-key:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    @media (min-width: 768px) {
        .api-key-wrapper {
            width: 500px;
        }
    }
}