.ebay-feedback {
    .card-inner {
        padding: 1.5rem;
    }

    .feedback-msg {
        display: flex;
        margin-bottom: 15px;

        .form-group {
            width: 100%;
        }
    }

    .remove-feedback-msg-btn {
        display: flex;
        margin-top: 10px;
    }

    .remove-feedback-msg-btn .fas {
        text-align: right;
        width: 30px;
        color: rgb(248, 59, 59);
        font-size: 18px;
    }

    .remove-feedback-msg-btn .fas:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .add-feedback-msg-btn {
        margin-top: 10px;
    }
    
    .form-footer {
        float: right;
    }
}