.trademe-shipping {
    .card-inner {
        margin: 1.5rem;
    }

    .add-shipping-policy-btn {
        width: 100%;
    }
    
    .add-shipping-policy-btn .btn {
        float: right;
    }
    
    .default-btn {
        margin-right: 8px;
    }
    
    .edit-btn i {
        vertical-align: initial !important;
        font-size: 0.9rem !important;
    }

    .next-btn button {
        float: right;
        margin-right: 20px;
        margin-top: 50px;
        margin-bottom: 10px;
    }
}