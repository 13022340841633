.support {
    .support-card {
        padding: 25px;
    }

    .more-info {
        margin-bottom: 20px;
    }

    textarea {
        resize: none;
        white-space: pre-line !important;
    }

    .form-footer div {
        float: right;
    }
}