.react-bootstrap-table {
    background-color: #FFF;
    overflow-x: auto;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.react-bootstrap-table-pagination {
    margin: 10px 5px 5px 5px;
}


.sku-column {
    width: 100px;
}

.table-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}