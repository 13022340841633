

.get-started-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 360px;
    height: 300px;
    z-index: -1;

    .close-icon {
        top: 10px !important;
    }
}

.shopping-feed-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 360px;
    height: 300px;
    z-index: -1;

    .close-icon {
        top: 10px !important;
    }
}

.shopping-feed-modal-content {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
}

.shopping-feed-modal-content .shopping-feed-modal-footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.shopping-feed-modal-footer-buttons {
    display: flex;
    flex-direction: row;
}

@media (min-width: 768px) {
    .shopping-feed-modal {
        width: 500px;
        height: 500px;
    }
}

.disable-app {
    margin-bottom: 12px;
}

.disable-app>span {
    color: #0000EE;
    text-decoration: underline;
    font-size: 14px;
}

.disable-app>span:hover {
    color: rgb(0, 173, 231);
    cursor: pointer;
}



.get-started-content {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
}

.get-started-content .get-started-footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.confirm-modal-buttons {
    display: flex;
    flex-direction: row;
}

.apps {

    .new-app {
        margin-left: 8px;
        background-color: #467fcf;
        color: #fff;
        font-size: 12px;
        border-radius: 6px;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 3px;
        padding-bottom: 3px;
        font-weight: bold;
        position: relative;
        bottom: 2px;
    }

    .category-subheader {
        margin-bottom: 20px;
        font-size: 0.9em;
    }

    .app-item {
        background-color: #FFF;
        padding: 10px 10px 0 10px;
        margin-bottom: 20px;
        height: 100%;
        position: relative;
        min-height: 250px;
    }

    .triangle-top-right {
        width: 0;
        height: 0;
        border-top: 50px solid #0c0;
        border-left: 50px solid transparent;
        position: absolute;
        right: 0;
        top: 0;
    }

    .false-triangle {
        border-top: 50px solid red !important;
    }

    .installed-tick {
        position: absolute;
        right: 7px;
        top: 7px;
        color: #FFF;
        font-size: 17px;
    }

    .app-item-header {
        display: flex;
        flex-direction: row;
        margin-right: 40px;
    }

    .app-item-header-title {
        margin-left: 10px;
        flex: 1;
    }

    .app-item-header-title .title {
        font-size: 1.1rem;
        font-weight: 600;
        word-break: break-word;
    }

    .app-item-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .app-item-body-inner {
        padding-top: 15px;
        padding-bottom: 20px;
        flex: 1;
    }

    .install-button,
    .configure-button {
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background-color: #467fcf;
        color: #FFF;
        padding-bottom: 5px;
        padding-top: 5px;
        font-family: "Arial", serif;
        font-weight: 600;
        font-size: 0.95em;
    }

    .install-button:hover,
    .configure-button:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .install-button .fa-download,
    .configure-button .fa-cog {
        color: #FFF;
    }

    .configure-button {
        background-color: #696969;
    }
}