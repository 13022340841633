.subscription-wrapper {
    display: flex;
    align-content: center;
    align-items: center;
    height: 100%;

    .subscription-card {
        background-color: #FFF;
        width: 100%;
        margin-top: 20px;
        margin-right: 10px;
        padding: 10px;
        border: 1px solid #CCC;
        border-radius: 2px;
        height: 350px;
        display: flex;
        flex-direction: column;
    }

    .plan-name {
        font-weight: bold;
        text-align: center;
        font-size: 1.2em;
        margin-bottom: 10px;
    }

    .plan-price {
        text-align: center;
        font-size: 1.3em;
        margin-bottom: 15px;
    }

    .plan-check {
        color: #467fcf;
    }

    .selected-plan {
        background-color: #047e00;
        border: 1px solid #047e00;
        font-weight: 600;
        font-size: 16px;
        padding: 8px 20px;
        outline: none;
        letter-spacing: 0.5px;
        transition: all 0.3s;
        border-radius: 6px;
        color: #FFF;
        text-align: center;
        width: 80%;
    }

    .plan-button {
        background-color: #2f55d4;
        border: 1px solid #2f55d4;
        font-weight: 600;
        font-size: 16px;
        padding: 8px 20px;
        outline: none;
        letter-spacing: 0.5px;
        transition: all 0.3s;
        border-radius: 6px;
        color: #FFF;
        text-align: center;
        width: 80%;
        box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    }

    .plan-attribute {
        font-size: 1.05em;
    }

    .plan-button:hover,
    .plan-button:focus {
        cursor: pointer;
        background-color: #2443ac;
        border-color: #2443ac;
    }

    .subscriptions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 50px;
        margin-left: 50px;
    }

    .subscription-wrapper-inner {
        width: 100%;
        height: 100%;
        padding-top: 30px;
    }

    .subscription-card:last-of-type {
        margin-bottom: 20px;
    }

    @media (min-width: 768px) {

        .subscriptions {
            flex-direction: row;
            justify-content: center;
            margin-right: 0;
            margin-left: 0;
        }

        .subscription-card {
            width: 250px;
            margin-top: 0;
        }

        .subscription-wrapper-inner {
            padding-top: 0;
            padding-bottom: 0;
            height: initial;
        }

        .subscription-card:last-of-type {
            margin-bottom: 0;
        }

        .subscription-card:first-of-type {
            margin-left: 10px;
        }
    }


}