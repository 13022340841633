.inventory-location-form {
    padding: 15px;
    margin-bottom: 25px;

    .custom-checkbox, .custom-radio {
        display: inline-block;
    }

    .inventory-locations-footer {
        margin-top: 25px;  

        div {
            float: right;
            margin-bottom: 15px;
        }

        button:last-child {
            margin-left: 10px;
        }

        button {
            width: 120px;
        }
    }    
}