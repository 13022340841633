.progress-div {
background-color: rgb(233, 233, 233);
border-radius: 0.3rem;
}

.progress {
background: rgb(70,127,207);
background: linear-gradient(
    90deg,
    rgba(70,127,207, 1) 100%,
    rgba(70,127,207, 0.6264880952380952) 100%,
    rgba(70,127,207, 0.4332107843137255) 100%
);

height: 14px;
border-radius: 0.3rem;
margin: 0;
}

.progressComp h1 {
font-size: 10px;
}

.percent-number {
color: rgb(121, 121, 121);
}


