.shopping-feed-instructions {
    .card-inner {
        padding: 1.5rem;
    }

    .step-header {
        font-size: 1.05em;
        font-weight: bold;
    }

    .step-body {
        margin-bottom: 15px;
    }

    .connect-settings {

        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .connect-label {
            font-weight: bold;
        }
    }

    .connect-footer {
        margin-right: 20px;
        margin-bottom: 20px;
        margin-top: 0;
    }

    .connect-footer .button-container {
        float: right;
    }

    @media(min-width: 768px) {

        .connect-settings {
            flex-direction: row;
        }

        .connect-label {
            width: 270px;
            font-weight: bold;
            margin-bottom: 15px;
        }     
    }
}