.instagram-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.instagram-icon {
    margin-left: 15px;
}

.instagram-follow-content {
    margin-left: 5px;
}

.install-app-wrapper,
.app-settings-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.install-app-content {
    margin-left: 0;
    width: 100%;
    overflow-x: auto;
}

.app-settings-content {
    margin-left: 0;
    width: 100%;
    overflow-x: auto;
}

@media (min-width: 992px) {
    .app-settings-wrapper {
        flex-direction: row;
    }

    .install-app-wrapper {
        flex-direction: row;
    }

    .app-settings-content {
        margin-left: 10px;
    }

    .install-app-content {
        margin-left: 10px;
    }
}

.save-toolbar {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 65px;
    background-color: #FFF;
    z-index: 999999;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-clip: border-box;
    border-top: 1px solid rgba(0, 40, 100, 0.12);
}

.save-toolbar-inner {
    margin-left: auto;
    margin-right: 30px;
}

.toolbar-cancel-action {
    margin-right: 15px;
}

#root {
    height: 100%;
}

.back-btn {
    margin-Bottom: 10px;
    display: inline-block;
}

.indent {
    margin-left: 15px;
}

.custom-form-group {
    margin-top: 15px;
}

.custom-label {
    display: flex;
}

.custom-label .form-label {
    margin-right: 5px;
}

.custom-label .tooltip-message {
    width: 280px;
}

.custom-label i {
    color: rgb(126, 126, 126);
}

.custom-label i:hover {
    cursor: pointer;
}

.dropdown-menu .dropdown-item:hover {
    cursor: pointer;
}

.clear-fix {
    clear: both;
}

.ag-cell-focus,
.ag-cell-no-focus {
    border: none !important;
    cursor: text;
}

/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
    border: none !important;
    outline: none;
    cursor: text !important;
}

html body {
    overflow-x: hidden;
    overflow-y: auto;
}

/*Scroll bar nav*/
html body *::-webkit-scrollbar {
    width: 15px;
    -webkit-appearance: none;
    height: auto;
    transition: auto;
}

/* Track */
html body *::-webkit-scrollbar-track {
    -webkit-box-shadow: auto;
    -webkit-border-radius: auto;
    border-radius: auto;
    background: auto;
}

/* Handle */
html body *::-webkit-scrollbar-thumb {
    -webkit-border-radius: auto;
    border-radius: auto;
    background: auto;
    -webkit-box-shadow: auto;
}

html body *::-webkit-scrollbar-thumb:window-inactive {
    background: auto;
}

html body *:hover::-webkit-scrollbar-thumb {
    background: auto;
}

.basic-single {
    z-index: 1 !important;
}

.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
    z-index: -1;
    padding-bottom: 20px;
}

.custom-modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10;
}

.ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.col {
    min-width: 100%;
}

.ag-selection-checkbox:hover {
    cursor: pointer;
}

.block-radio .custom-control {
    display: inline-block;
}

.footer {
    display: none;
}

.page-content {
    margin-top: 10px !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    padding-left: 10px;
    padding-right: 10px;
}

.page-header .page-title {
    font-size: 1.1rem;
    line-height: 0.5rem;
    margin-left: 3px;
}

.anchor {
    color: #467fcf;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration-skip-ink: auto;
}

.anchor:hover {
    color: #295a9f;
    text-decoration: underline;
    cursor: pointer;
}

.undo-btn {
    margin-right: 10px;
}

.custom-breadcrumb {
    margin-bottom: 20px;
    font-size: 0.95em;

}

.custom-breadcrumb:hover {
    cursor: pointer;
    opacity: 0.8;
}

@media (min-width: 768px) {

    .page-header .page-title {
        line-height: 1.5rem;
        font-size: 1.3rem;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }

    .container {
        width: 98%;
        max-width: 3000px;
    }

    .custom-modal {
        width: 720px;
    }

    .ReactModal__Body--open {
        overflow: hidden;
        position: initial;
        width: initial;
        height: initial;
    }

    .col {
        min-width: initial;
    }

    .footer {
        display: block;
    }

    .page-content {
        margin-top: 1.5rem !important;
    }
}


@media (min-width: 992px) {

    .page-header .page-title {
        line-height: 2.5rem;
        font-size: 1.5rem;
    }

    .custom-modal {
        width: 900px;
    }
}