.product-error-modal-content {
    padding: 20px;

    .error-container {
        padding-left: 15px;
        margin-bottom: 20px;
    }

    .loader-container {
        display: flex;
        justify-content: center;
    }

    .product-error-modal-footer {
        float: right;
    }

    .product-error-modal-footer button {
        margin-left: 10px;
    }

    .error-text {
        margin-top: 5px;
    }

}