.trademe-general {
    .card-inner {
        padding: 1.5rem;
    }

    .general-footer {
        float: right;
        margin-top: 1.5rem;
    }

}