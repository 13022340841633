.shipping-method-modal {
    padding: 15px;

    .custom-checkbox, .custom-radio {
        display: inline-block;
    }

    .shipping-method-modal-footer {
        margin-top: 25px;  

        div {
            float: right;
            margin-bottom: 15px;
        }

        button:last-child {
            margin-left: 10px;
        }

        button {
            width: 120px;
        }
    }

    .shipping-method-field {
        max-width: 350px;
    }

    .shipping-method h5 {
        margin-bottom: 10px;
        margin-top: 25px;
    }

    .fa-minus-circle {
        color: #fd5353;
    }

    .fa-minus-circle:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .remove-shipping-method-icon {
        font-size: 0.8em;
        margin-left: 2px;
    }
}