.ebay-general {
    .card-inner {
        padding: 1.5rem;
    }

    .general-footer {
        float: right;
        margin-top: 1.5rem;
    }
    
    .template-example-btn {
        color: #467fcf;
        margin-bottom: 5px;
        display: inline-block;
    }

    .template-example-btn:hover {
        color: #295a9f;
        text-decoration: underline;
        cursor: pointer;
    }
}

.html-template-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
    z-index: -1;

    .close-icon {
        top: 10px !important;
    }
}

.html-template-content {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
}

.html-template-modal-buttons {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 15px;
}

.code-font {
    font-family: "Consolas", "Monaco", "Courier New";
    margin-top: 15px;
}

@media (min-width: 768px) { 
    .html-template-modal {
        width: 720px;
        height: 95%;
    }

}