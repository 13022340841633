.templates-wrapper {

    min-width: 300px;
    
    .card-inner {
        margin: 1.5rem; 
    }

    .clipboard-icon {
        margin-right: 10px;
        color: #666;
        font-size: 1.2em;
    }

    .clipboard-icon:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .copied-clipboard-outer {
        display: none;
        position: absolute;
        top: 8px;
        left: -34px; 
        line-height: initial;
        background-color: #f8f9fa;
        padding: 3px;
        border-radius: 3px;
    }

    .copied-clipboard-outer div {
        font-size: 12px;
        font-weight: bold;
        color: #999;
    }

    .copied-clipboard-icon {  
        color: #666;
    }

    .template-icon {
        margin-right: 10px;
        font-size: 1.2em;
        color: #467fcf;
    }

    .template-icon:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .template-icon-danger {
        color: red;
    }

    .add-template-btn {
        width: 100%;
    }
    
    .add-template-btn .btn {
        float: right;
    }

    .manage-cell {
        text-align: right;
        .ag-cell-wrapper {
            display: initial;
        }
    }

    .card-header h4 {
        width: 160px;
    }

    .manage-btns {
        padding: 3px;
    }

    .manage-header {
        height: 0;
    }
}