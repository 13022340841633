.shipping-services {

    padding: 15px;
    margin-bottom: 25px;

    .custom-checkbox, .custom-radio {
        display: inline-block;
    }

    select {
        font-size: 14px;
    }

    .international-postage {
        margin-top: 30px;
    }

    .domestic-postage {
        margin-top: 30px;
    }

    .add-shipping-service-btn {
        margin-top: 13px;
    }

    .free-postage-group {
        margin-top: 15px;
    }

    .postage-detail-input {

        margin-top: 10px;
        display: flex;

        flex-direction: column;
        
        .form-label {
            font-size: 12px;
        }

        .input-group, .form-group {
            width: 185px !important;
            padding-right: 15px;
        }
    }

    .handling-detail-input {

        margin-top: 20px;

        .form-label {
            font-size: 12px
        }

        .input-group, .form-group {
            width: 230px !important;
            padding-right: 15px;
        }
    }

    .postage-selection {
        display: flex;
        margin-top: 5px;

        .form-group {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .remove-shipping-service-btn {
        display: flex;
        margin-top: 10px;
        margin-left: 15px;
        margin-right: 5px;
    }

    .remove-shipping-service-btn i {
        color: var(--primary);
        font-size: 20px;
    }

    .remove-shipping-service-btn i:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .shipping-policy-footer {
        margin-top: 25px;  

        div {
            float: right;
            margin-bottom: 15px;
        }

        button:last-child {
            margin-left: 10px;
        }

        button {
            width: 120px;
        }
    }

    .international-shipping-locations {
        font-size: 0.95em;
        margin-top: 10px;
        margin-bottom: 10px;

        .custom-checkbox {
            margin-bottom: 2px;
        }
    }


    @media (min-width: 768px) {
        .postage-detail-input {
            flex-direction: row;
        }
    }


}