.ReactModal__Overlay .close-icon {
    float: right;
    font-size: 24px;
    color: var(--secondary);
    position: absolute;
    right: 20px;
    top: 20px;
}

.ReactModal__Overlay .close-icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.ReactModal__Overlay .category-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--gray-dark);
    padding-left: 20px;
    padding-top: 20px;
}

.mapping-category {
    background-color: yellow;
    color: rgb(214, 68, 68);
    font-weight: 600;
    padding: 1px 2px 1px 2px;


}
.catgory-loader {
    margin-left: 15px;
}

.ReactModal__Overlay .divider {
    margin-top: 5px;
    margin-bottom: 15px;
}

.ReactModal__Overlay .parent-categories {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ReactModal__Overlay .parent-categories li .parent-category-icon{
    width: 40px;
    text-align: center;
}

.ReactModal__Overlay .parent-categories li .parent-category-header {
    font-size: 1.2em;
    font-weight: 500;
}

.ReactModal__Overlay .category-recommendations {
    margin-left: 40px;
    padding-right: 15px;
    margin-bottom: 8px;
    min-height: 215px;
}

.ReactModal__Overlay .category-recommendations .category-recommendation {
    display: inline-block;
}

.ReactModal__Overlay .no-category-recommendations {
    margin-top: 10px;
    font-size: 0.98em;
}

.ReactModal__Overlay .category-recommendations-input {
    width: 100%;
}

.ReactModal__Overlay .category-recommendations-search {
    margin-bottom: 10px;
}

.ReactModal__Overlay .category-recommendations-header {
    font-size: 1.2em;
    font-weight: 500;
}

.ReactModal__Overlay .category-recommendations-body {
    padding-top: 10px;
    padding-bottom: 10px;

    line-height: 1.65em;
}


.ReactModal__Overlay .parent-categories li .parent-category {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ReactModal__Overlay .parent-categories .expandable:hover {
    cursor: pointer;
}

.ReactModal__Overlay .child-categories {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
}

.ReactModal__Overlay .child-categories li:hover {
    cursor: pointer;
}

.ReactModal__Overlay .child-categories li .child-category {
    display: flex;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid rgb(204, 204, 204);
}

.ReactModal__Overlay .child-categories li .child-category .child-category-header {
    width: 100%;
}

.ReactModal__Overlay .child-categories li .child-category-icon{
    width: 40px;
}

.category-wrapper {
    overflow-y: auto;
    height: calc(100% - 150px)
}

.category-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: none;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
    z-index: -1;
}

.category-modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10;
}

@media (min-width: 768px) {
    .ReactModal__Overlay .category-recommendations-input {
        max-width: 400px;
    }
}