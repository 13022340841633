.add-template {

    padding: 20px;
    
    .template-loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .add-template-footer {
        margin-top: 25px;  

        div {
            float: right;
            margin-bottom: 15px;
        }

        button:last-child {
            margin-left: 10px;
        }

        button {
            width: 120px;
        }
    }
}

