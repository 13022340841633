.currency {
    .currency-card {
        padding: 1.5em;
    }

    .currency-footer {
        float: right;
        margin-top: 1.5rem;
    }

    #currency-grid {
        padding-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: 768px) {
        .small-inputs {
            max-width: 300px;
        }
    }
}