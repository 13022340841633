.ReactModal__Overlay .close-icon {
    float: right;
    font-size: 24px;
    color: var(--secondary);
    position: absolute;
    right: 20px;
    top: 20px;
}

.ReactModal__Overlay .close-icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.ReactModal__Overlay .category-header {
    text-align: center;
    flex-direction: column;
    color: var(--gray-dark);
    padding-left: 40px;
    padding-top: 20px;
    padding-right: 40px;
    word-break: break-word;
}

.ReactModal__Overlay .divider {
    margin-top: 5px;
    margin-bottom: 15px;
}

.ReactModal__Overlay .subtitle {
    margin-top: -13px;
    color: rgb(74, 74, 74);
    font-size: 1.03em;
}