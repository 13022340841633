.product-detail {

    .save-asin-icon {
        margin-left: 10px;
        font-size: 22px;
        color: #467fcf
    }

    .save-asin-icon:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .manual-item-specifics-tooltip-wrapper {
        margin-left: 8px;
        margin-bottom: 10px;
    }

    .asin-tooltip-wrapper {
        margin-left: 8px;
        margin-bottom: 10px;
    }

    .product-variations {
        overflow-x: scroll;
    }

    .no-manual-specifics {
        margin-top: 20px;
    }

    .item-specifics-table {
        overflow-x: scroll;
        margin-top: 15px;
    }

    .item-specifics-selection-wrapper {
        display: block;
    }

    .remove-item-specific-icon {
        color: rgb(97, 96, 96);
    }

    .remove-item-specific-icon:hover {
        color: red;
        cursor: pointer;
        opacity: 0.8;
    }

    .item-specifics-input {
        width: 100%;
        margin-top: 10px;
    }

    .item-specifics-btn {
        width: 100%;
        margin-top: 10px;
    }

    .item-specifics-select-wrapper {
        width: 100%;
    }

    .no-manual-specifics {
        margin-top: 5px;
    }

    .view-product:hover {
        opacity: 0.8;
        color: #467fcf;
        cursor: pointer;
    }

    .view-product {
        margin-bottom: 25px;
        color: #495057;
        display: block;
        text-decoration: none;
    }

    .image-placeholder {
        color: #949494;
        font-size: 50px;
    }

    .main-images {
        display: flex;
        flex-wrap: wrap;
    }

    .main-images .product-image-container {
        border: 1px solid #CCC;
        border-radius: 3px;
        padding: 1px;
        margin-right: 15px;
        margin-top: 10px;
        text-align: center;
        height: 100%;
    }

    .main-images img {
        width: 180px;
        display: inline-block;
        transition: 0.3;
    }

    .main-images img:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .variant-image img {
        width: 50px;
    }

    .unlist-product {
        width: 100%;
    }

    @media (min-width: 992px) {

        .item-specifics-selection-wrapper {
            display: flex;
        }

        .item-specifics-input {
            margin-top: 0;
            margin-left: 5px;
        }

        .item-specifics-btn {
            margin-top: 0;
            margin-left: 10px;
            width: 85px;
        }

        .item-specifics-select-wrapper {
            margin-right: 5px;
        }

        .no-manual-specifics {
            margin-top: 30px;
        }

        .unlist-product {
            width: initial;
            float: right;
            margin-bottom: 20px;
        }

    }
}