.ebay-recommendations {
    padding: 10px 30px 10px 30px;

    .recommendations-loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .recommendations-disclaimer {
        margin-bottom: 15px;
    }

    .recommendation-value {
        font-weight: 600;
    }
}