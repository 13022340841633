.products {

    margin: 1.5rem 0 0 0 !important;

    .product-info-button {
        margin-right:  7px;
    }

    .products-header {
        padding: 20px;
        display: flex;
        flex-direction: column-reverse;
    }

    .search-input {
        width: 100%;
    }

    .search-bar {
        display: flex;
    }

    .action-menu {
        margin-left: auto;
        margin-right: 12px;
    }

    .ag-center-cols-container {
        font-size: 14px;
    }

    #productsGrid {
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .product-warning {
        color: orange;
        text-decoration: underline;
    }

    .product-error {
        color: #cd201f;
        text-decoration: underline;
        display: inline-block;
        padding-right: 3px;
    }

    .product-listed {
        color: green;
    }

    .product-not-listed {
        color: red;
    }

    .product-warning:hover, .product-error:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .action-menu {
        display: flex;
        flex-direction: row;
        margin-bottom: 7px;
        margin-top: -10px;
        margin-right: 0;
    }

    .refresh-btn {
        margin-left: 10px;
    }

    .btn {
        font-size: 0.72rem;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .bg-success-link:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    @media (min-width: 768px) {
        .products-header {
            flex-direction: row;
        }

        #productsGrid {
            width: 100%;
        }

        .search-input {
            width: 400px;
        }

        .action-menu {
            margin-bottom: 0;
            margin-top: 0;
            margin-right: 12px;
        }

        .btn {
            font-size: 0.8125rem;
            padding: 0.375rem 0.75rem;
        }
    }

}