.dashboard {

    .icon-wrapper i {
        font-size: 20px;
    }

    .draggable-heading:hover {
        cursor: move;
    }

    .grid-stack-wrapper {
        margin-left: -10px;
        margin-right: -10px;
    }

    .grid-stack {
        width: 100%;
        margin-left: 10px;
      }
      
      .grid-stack-item-content {
        overflow-y: hidden;
      }

      .custom-panel-body {
          overflow: auto;
      }

      .custom-panel-mobile {
          margin-bottom: 10px;
          width: calc(100% - 20px);
          margin-left: 10px;
      }

      .custom-panel {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        overflow-y: hidden;
        margin-bottom: 0;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }

    .sync-header {
        margin-left: 8px;
        position: absolute;
        top: 10px;
        right: 20px;
        display: flex;
    }

    .purge-and-replace-button {
        margin-left: 5px;
    }

    .sales-inner {
        padding: 0 30px 30px 30px;
    }

    .alert-check {
        color: #467fcf;
    }

    .alerts {
        padding: 20px 30px 30px 30px;
    }

    .card-header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 0.66em;
    }

    .widget-refresh {
        margin-left: 12px;
        color: #737e89;
    }

    .widget-settings, .widget-refresh {
        font-size: 18px;
    }

    .widget-settings:hover, .widget-refresh:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .card-header-inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    .card-header-inner h4 {
        margin-bottom: 0;
        margin-left: 8px;
    }

    .row-inner {
        display: flex;
        margin-bottom: 8px;
    }

    .last-sync {
        padding-left: 30px; 
        padding-right: 30px;
        padding-top: 20px;
    }

    .row-outer {
        margin-bottom: 0;
        padding: 5px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .row-border {
        border-bottom: 1px solid rgba(0, 40, 100, 0.12);
    }

    .row-name {
        flex: 1;
        font-size: 1.1em;
    }

    .row-value {
        font-size: 1.1em;
        max-width: 50%;
        text-align: right;
    }

    .bold {
        font-weight: 700;
    }

    .errors-link {
        color: red;
        text-decoration: underline;
    }

    .active-link {
        color: green;
        text-decoration: underline;
    }

    .misc-link {
        color: #467fcf;
        text-decoration: underline;
    }

    .sales-summary {
        display: flex;
    }

    .sales-summary-outer {
        margin-bottom: 5px;
        padding: 5px;
    }

    .sales-summary-border {
        border-bottom: 1px solid rgba(0, 40, 100, 0.12);
    }

    .sales-summary .period-name {
        flex: 1;
    }

    .sales-summary .period-value {
        font-weight: 700;
    }

    .sales {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .pie-graph-day-group {
        width: 240px;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .pie-graph-day-group .selectgroup-button {
        font-size: 12px;
    }

    .listings-panel {
        padding-top: 12px;
        min-height: 300px;
    }

    .chart-wrapper {
        height: 270px;
        padding-top: 10px;
        margin-bottom: 20px;
    }

    #marketplace-pie {
        height: 200px;
        padding-top: 10px;
        margin-bottom: 20px;
    }

    .trademe-sales, .ebay-sales, .amazon-sales, .widget-card {
        padding-top: 12px;
    }

    .alerts .row-inner {
        display: block;
        margin-bottom: 8px;
    }

    .alerts .row-value {
        text-align: left;
        margin-top: 10px;
        max-width: 100%;
    }

    .alerts .row-value ul {
        padding-left: 20px;
    }

    .alerts .row-value ul li div {
        font-size: 0.90em;
        color: red;
    }
}