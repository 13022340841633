.createable-pre-value, .createable-pre-option {
    color: initial;
    padding: initial;
    overflow: auto;
    font-size: initial;
    line-height: initial;
    background-color: initial;
    border-radius: initial;
    -moz-tab-size: initial;
    tab-size: initial;
    text-shadow: initial;
    -webkit-hyphens: initial;
    hyphens: initial;
    font-family: inherit;
    margin: 2px;
    font-size: 15px;
}