.external-category-mappings {
    .external-category-mappings-table .fa-exclamation-triangle {
        color: var(--red);
    }
    
    .category-mapping-loader {
        float: right;
        margin-right: 15px;
        color: var(--primary);
    }

    .card-inner {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }

    .next-btn button {
        float: right;
        margin-right: 20px;
        margin-top: 50px;
        margin-bottom: 10px;
    }

    .ignore-btn {
        margin-right: 10px;
    }
}

