.inventory-locations {
    .card-inner {
        padding: 1.5rem;
    }

    .add-inventory-location-btn {
        width: 100%;
    }
    
    .add-inventory-location-btn .btn {
        float: right;
    }

    .inventory-locations-header {
        width: 250px;
    }

    .default-btn {
        margin-right: 8px;
    }
    
    .edit-btn i {
        vertical-align: initial !important;
        font-size: 0.9rem !important;
    }

    .next-btn button {
        float: right;
        margin-right: 20px;
        margin-top: 50px;
        margin-bottom: 10px;
    }
}