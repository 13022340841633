.marketplace-health {
    padding-bottom: 8px;

    .progress-status {
        text-align: center;
        margin-bottom: 4px;
        margin-top: 4px;
        font-size: 13px;
    }

}