.marketplace-templates {

    min-width: 300px;
    
    .card-inner {
        margin: 1.5rem; 
    }

    .template-icon {
        margin-right: 10px;
        font-size: 1.2em;
        color: #467fcf;
    }

    .template-icon:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .template-icon-danger {
        color: red;
    }

    .add-listing-loader-template-btn {
        width: 100%;
    }
    
    .add-listing-loader-template-btn .btn {
        float: right;
    }

    .manage-cell {
        text-align: right;
        .ag-cell-wrapper {
            display: initial;
        }
    }

    .card-header h4 {
        width: 160px;
    }

    .manage-btns {
        padding: 3px;
    }

    .manage-header {
        height: 0;
    }
}