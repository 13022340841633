.pause-products-modal-content {
    padding: 20px;

    .confirm-footer {
        margin-top: 50px;
        float: right;
    }

    .confirm-cancel-btn {
        margin-right: 10px;
    }

    .confirm-close-icon {
        position: relative;
        float: right;
        top: -10px;
    }

    .confirm-close-icon:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

