.settings-menu {

    .card-inner {
        padding-top: 10px;
        padding-bottom: 25px;
        padding-right: 10px;
        padding-left: 10px;
    }

    .desktop-settings-menu {
        display: none;
        margin-left: 5px;
        margin-right: 5px;
    }

    .mobile-settings-menu {
        display: block;
        margin-bottom: 15px;
    }

    .mobile-settings-menu select {
        height: 45px !important;
    }

    .app-header {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .app-item-logo {
        min-width: 50px;
        height: 50px;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .app-item-logo img {
        border-radius: 8px;
        min-width: 50px;
        height: 50px;
    }

    @media (min-width: 992px) {
        .desktop-settings-menu {
            display: block;
            margin-left: 0;
            margin-right: 0;
        }

        .mobile-settings-menu {
            display: none;
        }

        width: 300px;
    }
}