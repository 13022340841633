@import  url(https://fonts.googleapis.com/css?family=Montserrat);

$width: 15px;
$height: 15px;

$bounce_height: 30px;

body {
  position: relative; 
  width: 100%;
  height: 100vh;
  font-family: Montserrat;
}

.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  color: #467fcf;
  display: inline-block;
  margin-left: 5px;
  font-size: 18px;
}

.lds-dual-ring {
  width: 56px;
  height: 56px;
  margin-left: 20px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 4px solid #467fcf;
  border-color: #467fcf transparent #467fcf transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}