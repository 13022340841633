.custom-confirm-alert {

    .confirm-footer {
        margin-top: 50px;
        float: right;
    }

    .confirm-cancel-btn {
        margin-right: 10px;
    }

    .confirm-close-icon {
        position: relative;
        float: right;
        top: -10px;
    }

    .confirm-close-icon:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    @media (min-width: 769px) {
       width: 550px;
     }
}

.react-confirm-alert {
    background-color: #FFF;
    border-radius: 3px;
    padding: 15px;
    border: 1px #CCC solid;

    @media (max-width: 768px) {
        width: 90%;
    }
}

