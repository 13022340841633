.new-discount-campaign {
    .react-datepicker__close-icon::after {
        background-color: #467fcf;
    }

    .calendarBtn {
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid #c1c1c1;
        border-radius: 4px;
        background-color: #FFF;
        font: inherit;
        color: hsl(0, 0%, 50%);
        width: 225px;
        text-align: left;
        height: 47px;
    }

    .calendarIconPlaceholder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
        color: hsl(0, 0%, 50%);
    }

}