.trademe-payment-details {
    .card-inner {
        padding: 1.5rem;
    }

    .form-footer {
        float: right;
        margin-top: 0;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .payment-method {
        display: inline-block;
    }
}
